require.config({
  baseUrl: 'js/',
  // never timeout
  waitSeconds: 0,

  paths: {
    // this will make sure r.js excludes this module
    underscore: 'empty:',
    text: 'lib/require/text',
    json: 'lib/require/json',
    moment: 'lib/moment',

    jquery: 'lib/jquery',
    React: 'lib/react',

    connection: 'core/io/connection/connection',
    HttpConnection: 'core/io/connection/HttpConnection',

    WelcomeMessageBox: 'widgets/AppWelcomeMessageBox'
  }
});
